/*
*
*	Índice de contenido
*	1. 	Variables
*	2. 	Fuentes
*   3.  Estilos principales
*
*
*******************************************************************************************/



//	1. 	Variables
$rutaFuentes : '../fonts/';
$coleccionFuentes : (
	'Raleway' : (
		'Thin' : 100,
		'ThinItalic' : 100, 
		'Light' : 300,
		'LightItalic' : 300,
		'Regular' : 400,
		'Italic' : 400,
		'Medium' : 500,
		'MediumItalic' : 500,
		'SemiBold' : 600,
		'SemiBoldItalic' : 600,
		'Bold' : 700,
		'BoldItalic' : 700,
		'ExtraBold' : 800,
		'ExtraBoldItalic' : 800,
		'Black' : 900,
		'BlackItalic' : 900
    ),
    'AvenirLTStd' : (
        'Light' : 100,
        'LightOblique' : 100,
        'Book' : 300,
        'BookOblique' : 300,
        'Roman' : 400,
        'Oblique' : 400,
        'Medium' : 500,
        'MediumOblique' : 500,
        'Heavy' : 700,
        'HeavyOblique' : 700,
        'Black' : 900,
        'BlackOblique' : 900
    )
);

$ofertare-font-raleway : 'Raleway', sans-serif;
$ofertare-font-avenir : 'AvenirLTStd', sans-serif;


// 2. 	Fuentes
@each $fuente, $tipos in $coleccionFuentes {
	@each $nombre, $peso in $tipos {

		@font-face {
			font-family: quote($fuente);
			src:url(quote('#{$rutaFuentes}#{to-lower-case($fuente)}/#{$fuente}-#{$nombre}.eot'));
			src:url(quote('#{$rutaFuentes}#{to-lower-case($fuente)}/#{$fuente}-#{$nombre}.eot?#iefix')) format('embedded-opentype'),
				url(quote('#{$rutaFuentes}#{to-lower-case($fuente)}/#{$fuente}-#{$nombre}.ttf')) format('truetype'),
				url(quote('#{$rutaFuentes}#{to-lower-case($fuente)}/#{$fuente}-#{$nombre}.woff')) format('woff'),
				url(quote('#{$rutaFuentes}#{to-lower-case($fuente)}/#{$fuente}-#{$nombre}.woff2')) format('woff2');
			font-weight: $peso;
			@if str-index(to-lower-case($nombre), 'italic') != null or str-index(to-lower-case($nombre), 'oblique') != null {
                //@debug $fuente + ' ' + $nombre;
				font-style: italic;
			} @else {
				font-style: normal;
			}

		}

	}
}



// 3.   Estilos principales

body {
    font : {
        family: $ofertare-font-raleway;
        size: 16px;
        weight : 400
    }
}

$head-list : 'h1', 'h2', 'h3', 'h4', 'h5', 'h6';
$head-lenght : length($head-list); 
// @debug $head-lenght;

@while $head-lenght > 0 {
    h#{$head-lenght} {
        @if $head-lenght == 1 {
            font-family : $ofertare-font-avenir ;
            font-weight: 900;
        } @else {
            font-family : $ofertare-font-raleway;
            font-weight: 700;
        }
        line-height: 1.1;
        b, strong {
            font-weight: 900
        }
    }
    $head-lenght : $head-lenght - 1; 
} 

h2 {
    font-weight: 400;
    b, strong { font-weight: 700 }
}
