main.form_flujo{
	section{
		margin-top:30px;
		margin-bottom:30px;
		.row{
			justify-content: center;
		}
		#flow_property{
			margin-top: -39px;
		}
		.form_container{
			text-align: center;
			margin:0;
			padding:20px;
			border-radius:4px;
			.welcome{
				font-size: 20px;
				font-weight:bold;
				@include media-breakpoint-up(md) { 
					font-size: 20px;
					&.cert_prop{
						font-size: 16px;
						line-height: 1.19;
						letter-spacing: 0.26px;
					}
				}
			}
			h1{
				&.home{
					font-size: 5.5vw;
					text-align:center;
					padding-bottom:10px;
					font-weight:500;
					font-family:$ofertare-font-raleway;
					@include media-breakpoint-up(md) { 
						font-size: 28px;
					}

				}
			}
			
			h2{
				font-size: 5vw;
				text-align:center;
				padding-bottom:20px;
				font-family:$ofertare-font-raleway;
				@include media-breakpoint-up(md) { 
					font-size: 20px;
				}
			}
			.fb-login-button{
				margin-bottom: 20px;
    			display: inline-block;
			}
			.g-signin2{
				margin-bottom:20px;
				.abcRioButtonLightBlue{
					border-radius: 26px;
					border: solid 1.4px #c0c0c0;
					box-shadow: none;
					margin:auto;
					width: 269px !important;
					height: 46px !important;
					padding: 6px 24px;
				}
				.abcRioButtonContents{
					color: #504d4d;
					font-size: 13px !important;
					text-transform: uppercase;
				}
			}
			.btn.btn-danger{
				font-size: 4vw;
				width: 100%;
				margin-bottom:20px;
				display: inline-block;
    			margin-bottom: 20px;
				@include media-breakpoint-up(md) { 
					font-size: 14px;
					width: 256px;
					margin: auto;
				}
			}
			.login_link{
				margin-top: 10px;
				a{
					display:block;
					color:#3dabe3;
					font-size: 18px;
					font-weight: bold;
					text-decoration: underline;
					margin-top: 12px;
				}
			}
			input.form-control{
				margin-bottom:15px;
				font-family:$ofertare-font-raleway !important;
			}
		}
		@media (min-width: 600px){
			.form_container{
				margin: 25px 0;
			}
		}
		.form_house{
			margin-top: 25px;
			text-align: left;
			label.error{
					display: none;
				    margin: 0px;
				    font-size: 13px;
				    position: relative;
				    top: -15px;
				    padding-left: 5px;
				    color: color("blue","base");
			}
			i.required{
				color: #ff0000;
			}
			.form-control{
				border-radius: 24px;
				border: solid 1px #d5d5d5;
				height: 44px;
			}
		}
		.cuestion_home{
			
			form{
				display:none;
				padding: 0px 30px;
				text-align: left;
				margin-bottom: 20px;
				#anwers{
					padding-left:20px;
				}
				.cuestion{
					font-size: 4vw;
					@include media-breakpoint-up(md) { 
						font-size: 25px;
					}
				}
				.errorInput{
					display: none;
				}
				label{
					text-align: left;
					font-size: 4vw;
					@include media-breakpoint-up(md) { 
						font-size: 20px;
					}
				}

				
			}
			
		}
		.ending_paymente{
			display: none;
			.title{
				font-size: 5vw;
				text-align:center;
				padding-bottom:20px;
				font-family:$ofertare-font-raleway;
				@include media-breakpoint-up(md) {  
					font-size: 20px;
					margin:0px;
				}
			}
			.text{
				font-size: 4vw;
				text-align:center;
				padding-bottom:20px;
				font-family:$ofertare-font-raleway;
				@include media-breakpoint-up(md) {  
					font-size: 15px;
					padding-bottom:10px;
				}
				
			}
			.title-data{
				text-align: left;
				font-size: 3.5vw;
				color:color("blue","base");
				@include media-breakpoint-up(md) { 
					font-size: 15px;
					margin:0px;
				}
			}
			.name{
				text-align: left;
				font-size: 3.5vw;
				@include media-breakpoint-up(md) { 
					font-size: 15px;
					margin:0px; 
				}

			}
			.correo{
				text-align: left;
				font-size: 3.5vw;
				@include media-breakpoint-up(md) { 
					font-size: 15px;
					margin:0px;
				}

			}
			.data-property{
				text-align: left;
				font-size: 3.5vw;
				@include media-breakpoint-up(md) { 
					font-size: 15px;
					margin:0px;
					padding-bottom:20px;
				}
			}
			.number{
				background:color("grey","ligth-4");
				.certificate{
					border-bottom: 2px solid #fff;
					p{
						text-align: justify;
						@include media-breakpoint-up(md) { 
							padding: 10px 11px;
							font-size: 14px;
							margin: 0px;
						}
					}
				}
				.price-payment{
					@include media-breakpoint-up(md) { 
						font-size: 25px;
						font-weight:500;
						color:color("blue","base");
						margin:0px;
					}
				}
				.investigation{
					padding-bottom:20px;;
				}
			}
		}
		.netx{
			background: color("blue","base");
			display: inline-block;
			width: 80%;
			margin: auto;
			font-size: 5vw;
			border-radius: 4px;
    		border: none;
    		color:white;
    		padding: 3px 0px;
    		@include media-breakpoint-up(md) { 
    			margin-top: 20px;
    			font-size: 25px;
    		}

		}
	}
}