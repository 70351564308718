
/*
*
*	Índice de contenido
*	1. 	Header
*   2.  Footer
*   3.  Buttons
*   4.  Sliders
*
*
*******************************************************************************************/

html{
    height: 100%;
    box-sizing: border-box;
    min-height: 100%;
}
body {

    @include media-breakpoint-up(xl) { 
        padding-top: 106px;
    }
    position: relative;
    min-height: 100%;
    height: auto;
    main{
        background: url("/images/backfull.svg");
        background-position-y: -106px;
        &.form_flujo,&.pago-gracias{
            background: url("/images/back_flow.svg");
        }
    }
    .text-margin-top{
        margin-top:15%;
        font-size:20px; 
       
        
    }
    .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot{
        position: absolute;
        top: 0;
        height: 100%;
        width: 39px;
        /* background: red; */
        font-size: 35px;
        color: white;

    }
    .owl-carousel button.owl-next{
        right: 0;
    }
    .slider_home{
        .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot{
            display: none;
        }
    }
    .top_login{
        width: 97%;
        ul.navbar-nav{
            justify-content: flex-end;
        }
    }

    // 1.   Header
    & > header {

        a {
            color: black;
            &:hover, &:focus {
                text-decoration: none;
                outline: none 0;
            }
        }

        .navbar {
            color: lighten( map-get($shades, 'black'), 0.2% );

            // Logo
            .navbar-brand {
                margin-right: 0;
                img{
                    width: 112px;
                    height: 64px;
                }
            }

            // Menú
            .navbar-collapse {
                background-color: lighten(color('blue','base'), 20%);
            
                .navbar-nav {
    
                    .nav-link, .nav-link:hover {
                        color: inherit;
                        display: inline-block;
                        padding: 0.5em 2em;
                        text-decoration: none;
                    }
                    .active {
                        color: #3dabe3;
                    }

                    .dropdown-menu {
                        background-color: lighten(color('blue','base'), 30%);
                        border: {
                            radius: 0;
                            style : none;
                            width: 0;
                        }
                        width: 100%;
                        .dropdown-item {
                            &:hover {
                                background-color: color('blue','base');
                                border-color: color('blue','base');
                                color: white;
                            }
                        }
                    }
    
                }

                .dropdown-toggle:after {
                    border-left-width: 0.4em;
                    border-right-width: 0.4em;
                    border-top-color: color('blue','base');
                    border-top-width: 0.6em;
                }

                @include media-breakpoint-up(xl) { 
                    background-color: white;
                    flex-grow: 0;
                    margin-top: 24px;

                    .navbar-nav {
                        .nav-item {

                            // Links normales
                            %enlaces-hover {
                                background-color: transparent;
                                border-color: transparent;
                                color: color('blue','base');
                            }
                            .nav-link {
                                border : {
                                    color: transparent;
                                    radius: 4px;
                                    style: solid;
                                    width: 1px;
                                }
                                padding : {
                                    left: 1.3em;
                                    right: 1.3em;
                                }
                                &:hover {
                                    @extend %enlaces-hover;
                                    padding : {
                                        left: 1.3em;
                                        right: 1.3em;
                                    }
                                    &.dropdown-toggle:after {
                                        border-top-color: color('blue','base');
                                    }
                                }
                            }

                            // Submenú
                            &.dropdown {

                                &.show {
                                    .nav-link {
                                        @extend %enlaces-hover;
                                        padding : {
                                            left: 1.3em;
                                            right: 1.3em;
                                        }
                                    }

                                    .dropdown-toggle:after {
                                        border-top-color: color('blue','base');
                                    }
                                }
                                
                            }

                            // Último enlace: Quiero vender
                                .nav-link.sale {
                                    margin-left: 1px;
                                    border : {  color: color('red','base') }
                                    &:hover {
                                        background-color: darken(color('red','base'),10%);
                                        border-color: darken(color('red','base'),10%);
                                        color: white;
                                    }
                                }
                            
                        }
                    }
                }
                
            }

            // Iniciar sesión
            & > a[title="Iniciar sesión"]{
                border-bottom: 1px solid;
                margin-right: 25px;
            }
            & > a[title="Iniciar sesión"],& > a[title="Bienvenido"] {
                font-size: 3vw;
                i {
                    color: color('blue','base'); 
                    font-size: 2em 
                }
                &:hover {
                    color: color('red','base');
                }
                @include media-breakpoint-up(sm) {
                    font-size: 1rem;
                }
                @include media-breakpoint-up(xl) { 
                    position: absolute;
                    right: 20px;
                    top: 20px;
                }
            }


            @include media-breakpoint-up(sm) {

                & > a[title="Iniciar sesión"] {
                    i { font-size: 1em }
                }

            }

            @include media-breakpoint-up(xl) { 
                justify-content: space-between;
                padding: {
                    bottom: 0.5rem;
                    top: 4px;
                }
            }
        &.login{
            height: 38px;
            background-color: #f0f0f0;
            padding-top: 4px;
            display: none;
            @include media-breakpoint-up(lg){
                display: block;
            }
            a{

                float: right;
                i{
                    background: url("/images/login.svg");
                    display: inline-block;
                    height: 17px;
                    vertical-align: middle;
                    width: 17px;
                }
            }
        }
        }

        
        @include media-breakpoint-up(xl) { 
            background-color: white;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 10;
        }

    }

    // 2.   Footer
    & > footer {
        position: relative;
        bottom: -99px;
        width:100%;
        font-size: 0.875rem;
        padding: {
            bottom: 2rem;
            top: 1rem;
        }
        a { 
            color: inherit;
            &:hover {
                color: color('blue','dark-1');
                text-decoration: none;
            }
        }
        p { margin-bottom: 0 }
        ul {
            list-style: none;
            margin-bottom: 0;
            padding: 0;
            
            &.social {
                font-size: 1.8rem;
                margin-bottom: 20px;
                margin-top: 15px;
                @include media-breakpoint-up(xl) {
                    margin-bottom: 0px;
                }
            }
        }
    }


    // 3.   Buttons
    .btn.#{$ofertare-prefix}-btn {
        background: {
            clip : border-box;
            color: #3dabe3;
        }
        border: {
            color: transparent;
            radius: 24px;
            style: solid;
            width: 0;
        }
        color: white;
        font-size: 1.1rem;
        margin-top: 21px;
        overflow: hidden;
        position: relative;
        transition: background-color 150ms,
                    box-shadow 150ms,
                    font-size 150ms,
                    transform 150ms;
        text-transform: uppercase;
        &:hover, &:focus {
            background-color: darken(#3dabe3, 10%);
            border-radius: 24px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.6);
            transform: scale(1.1);
        }
    }
    .btn.ofertare-btn-white{
        background: {
            clip : border-box;
            color: white;
        }
        border: {
            color: #3dabe3;
            radius: 24px;
            style: solid;
            width: 2px;
        }
        color: #3dabe3;
        font-size: 1.1rem;
        margin-top: 21px;
        overflow: hidden;
        position: relative;
        transition: background-color 150ms,
        box-shadow 150ms,
        font-size 150ms,
        transform 150ms;
        text-transform: uppercase;
        &:hover, &:focus {
            color: darken(#3dabe3, 10%);
            border-radius: 24px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.6);
            transform: scale(1.1);
        }
    }



    // 4.  Sliders
    .slider {

        &.slider-horizontal {
            width: 70%;
            .slider-track {
                height : 6px;
                position: relative;

                &:before, &:after {
                    content: '';
                    font-size: 27px;
                    font-weight: bold;
                    position: absolute;
                    top: -0.6em;
                }
                &:before {
                    content: '-';
                    left: -1em;
                }
                &:after {
                    content: '+';
                    right: -1em;
                }
                .slider-selection { background: color('blue','base') }
            }

            .slider-handle {
                $sizea: 15px;
                background: #146994;
                height: $sizea;
                width: $sizea;
            }
        }

        /* The tooltip is hidden... or it ought to be
        .tooltip {
            $size : 5px;
            color: red;
            &.top {
                &.in {
                    opacity: 1;
                }
                .tooltip-arrow {
                    bottom: -$size;
                    border : {
                        color: transparent;
                        top-color: transparent;
                        style: solid;
                        width: $size $size 0;
                        top-color: #000;
                    }
                    height: 0;
                    left: 50%;
                    margin-left: -$size;
                    position: absolute;
                    width: 0;
                }
            }
        }
        */
    }

    .owl-ofertare {
        .owl-nav {
            position: absolute;
            top: 50%;
            width: 100%;
            button[class|='owl'] {
                $width: 20px;
                $height: 35px;


                color: color('blue','base');
                display: block;
                font-size: $height;
                outline: none 0;
                position: absolute;
                top: -($height/2);
                text-shadow: none;
                width: $width;
                &[class$='prev'] { left: 0 }
                &[class$='next'] { right: 0 }
            }
        }
    }
    #steps_header{
        background: none;
        ul{
            display: none;
            justify-content: space-evenly;
            margin: 3px auto;
            width: 606px;
            padding: 0;
            li{
                width: 25%;
                list-style: none;
                span{
                    color: #c0c0c0;
                    font-size: 12px;
                    font-weight: 600;
                    display:inline-block;
                    margin: 18px 0;
                    max-width: 75px;
                }
                &:before{
                    background-color: white;
                    border-radius: 21px;
                    content: ' ';
                    display: inline-block;
                    height: 8px;
                    width: 8px;
                    top: -33px;
                    right: -36px;
                    position: relative;
                }
                &.completed:after{
                    background-color:  #35a1df;
                    content: ' ';
                    display: inline-block;
                    height: 3px;
                    width: 133px;
                    top: -78px;
                    right: -77px;
                    position: relative;
                }
                &:nth-child(2){
                    &:before{
                        top: -51px;
                        right: -45px
                    }
                    &.completed:before{
                        top: -39px;
                        right: -45px
                    }
                    &.completed:after{
                        top: -94px;
                        right: -81px
                    }
                }
                &:nth-child(3){
                    &:before{
                        top: -33px;
                        right: -33px
                    }
                    &.current:before{
                        top: -23px;
                        right: -40px
                    }
                }
                &:nth-child(4){
                    &:before{
                        top: -51px;
                        right: -45px
                    }
                }
                &.active {
                    &:before {
                        border-radius: 21px;
                        background-color: #35a1df;
                        height: 18px;
                        margin: 0 0 0 -9px;
                        width: 18px;
                        top: -29px;
                        right: -36px;
                        position: relative;
                    }
                    span{
                        color: #35a1df;
                    }
                }
                &.completed{
                    &:before {
                        background: url("/images/ok.svg") no-repeat center;
                        border-radius: 21px;
                        background-color: #35a1df;
                        height: 33px;
                        margin: 0 0 0 -9px;
                        width: 33px;
                        top: -23px;
                        right: -36px;
                        position: relative;
                    }
                    span{
                        color: #35a1df;
                    }
                }
                &.current {
                    &:before {
                        border-radius: 21px;
                        background-color: white;
                        border: 9px solid #35a1df;
                        height: 32px;
                        margin: 0 0 0 -9px;
                        width: 32px;
                        top: -38px;
                        right: -50px;
                        position: relative;
                    }
                    span{
                        color: #35a1df;
                    }
                }
            }
        }
        @media (min-width: 600px){
            background: url("/images/steps.svg") no-repeat center top;
            ul {
                display: flex;
            }
        }
    }
    .btn-small{
        font-size: 13px !important;
        font-weight: bold;
        padding: 15px 60px;
        margin-top: -6px !important;
        &#step2{
            margin-top: 45px !important;
        }
    }
    #separator_line{
        color: #c0c0c0;
        font-size: 16px;
        margin-top: 33px;
        display: inline-block;
        &:before,&:after{
            content: ' ';
            background-color: #c0c0c0;
            display: inline-block;
            margin: 3px 18px;
            position: relative;
            height: 2px;
            width: 115px;
            @include media-breakpoint-up(lg){
                width: 157px;
            }
        }
    }

}
#btn_change_phone{
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
.block_thanks{
    margin: 25px 0;
    img{
        margin: 133px 0 25px 0;
        @include media-breakpoint-up(lg){
            margin-bottom: 25px;
        }
    }
    h1{
        color: #504d4d;
        font-size: 27px;
        line-height: 1.18;
        letter-spacing: 0.53px;
        margin: 0;
        @include media-breakpoint-up(lg){
            font-size: 33px;
        }
    }
    p{
        line-height: 1.19;
        font-size: 16px;
        color: #403f3d;
        margin-bottom: 45px;
        &.bolder{
            font-weight: bold;
            margin-bottom: 27px;
        }
    }
}