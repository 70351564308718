.container.register_form{
    .content_form{
        border:2px solid color("grey","light-3"); 
        box-shadow: 0px 3px 6px 0px rgba(29, 29, 29, 0.13);
        .panel.panel-default{
            h1{
                text-align: center;
                margin-top:20px;
                margin-bottom:10px;
            }
            #imageUpload{
                display: none;
            }
            #profileImage
            {
                cursor: pointer;
            }

            #profile-container {
                margin: auto;
                width: 150px;
                height: 150px;
                overflow: hidden;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                border-radius: 50%;
            }

            #profile-container img {
                width: 150px;
                height: 150px;
            }
            #file-upload{
                display: none;
            }
            .custom-file-upload {
                border: 1px solid #ccc;
                display: inline-block;
                padding: 6px 12px;
                cursor: pointer;
                width: 100%;
                text-align: center;
            }
        
        }
    }
}
.form_flujo #flow_property{
    color: #504d4d;
    span.error{
        display: block;
        margin: -18px 9px 0;
    }
    strong{
        font-size: 12px;
        color: #e50000;
    }
    .key_property{
        background-color: rgba(240, 240, 240, 0.71);
        padding: 6px;
        border-radius: 14px;
        font-size: 34px;
        font-weight: 800;
        line-height: 1.18;
        letter-spacing: 0.54px;
        margin-bottom: 45px;
    }
    .data_property{
        p.welcome{
            text-align: left;
        }
        &>div {

            border-radius: 14px;
            border: solid 2px #f0f0f0;
            padding: 18px;
            text-align: left;
            p {
                font-size: 16px;
                line-height: 1.19;
                letter-spacing: 0.26px;
                margin-left: 29px;
                &.title {
                    color: #3dabe3;
                    font-size: 12px;
                    font-weight: bold;
                    margin-left: 0;
                    margin-bottom: -6px
                }
                &.icon_home:before, &.icon_user:before {
                    content: ' ';
                    display: block;
                    left: -27px;
                    position: relative;
                    top: 18px;
                    height: 16px;
                    width: 16px;
                }
                &.icon_home:before {
                    background: url("/images/home.svg") no-repeat;
                }
                &.icon_user:before {
                    background: url("/images/user.svg") no-repeat;
                }
            }
        }
    }
    .cost_property{
        img{
            margin: 33px 0 18px;
        }
        p{
            font-size: 16px;
            font-weight: normal;
            line-height: 1.25;
            letter-spacing: 0.26px;
            text-align: left;
            &.cost {
                font-size: 22px;
                font-weight: 600;
                line-height: 1.18;
                letter-spacing: 0.35px;
                text-align: left;
                span {
                    color: #3da1e3;
                }
            }
        }
    }
    .buttons{
        margin-top: 57px;
        display: flex;
        flex-flow: column;
        @media (min-width: 1200px){
            display: inline-block;
        }
        span{
            margin: 9px;
        }
    }
}
#continue_outpay{
    .modal-content{
        padding: 18px 39px;
        span{
            font-weight: bold;
        }
    }
    .modal-footer{
        border: none;
        margin: 12px 0;
        flex-flow: column;
        @media (min-width: 1200px){
            flex-flow: row;
            flex-wrap: wrap;
        }
        .ofertare-btn{
            margin-bottom: 21px
        }
        a{
            color: #3dabe3;
            font-size: 14px;
            text-align: center;
            width: 100%;
        }
    }
}
#change_phone .ofertare-btn{
    margin-bottom: 21px;
}
.btn-cancel{
    background-clip: border-box;
    background-color: #c0c0c0;
    border-color: transparent;
    border-radius: 24px;
    border-style: solid;
    border-width: 0;
    color: white;
    font-size: 1.1rem;
    margin-top: 21px;
    overflow: hidden;
    position: relative;
    transition: background-color 150ms, box-shadow 150ms, font-size 150ms, transform 150ms, -webkit-box-shadow 150ms, -webkit-transform 150ms;
    text-transform: uppercase;
    font-size: 13px !important;
    font-weight: bold;
    padding: 15px 60px;
    margin-top: -6px !important;
    margin-bottom: 21px
}
@media (min-width: 1200px) {
    .modal.show .modal-dialog.payment_dialog {
        max-width: 800px;
    }
    .payment_dialog {
        .modal-body{
            padding: 33px 30px 0;
        }
        h2{
            font-size: 20px;
            font-weight: bold;
            line-height: 1.2;
        }
        .align-right{
            text-align: right;
        }
        .margin-bottom{
            margin-bottom: 21px;
        }
        .form-control {
            border-radius: 24px;
            border: solid 1px #d5d5d5;
            height: 44px;
        }
        .select_payment{
            text-align: left;
            label{
                margin: 12px 0 0;
                i{
                    color: #ce0000;
                }
            }
        }
        .modal-footer {
            border:none;
            justify-content: center;
            .ofertare-btn {
                margin-bottom: 21px;
            }
        }
    }
}