
.#{$ofertare-prefix}-hero {

    background : {
        attachment : fixed;
        position: center;
        repeat: no-repeat;
        size: cover;
    }

    &.#{$ofertare-prefix}-home {
        background-image: url('/images/home/ofertare-hero-600x600.jpg');
        @include media-breakpoint-up(lg) { background-image: url('/images/home/ofertare-hero-1000x1000.jpg') }
        @include media-breakpoint-up(xl) { background-image: url('/images/home/ofertare-hero-2000x500.jpg') }
    }
    &.#{$ofertare-prefix}-interna{
        background-image: url('/images/planes/planes-slider.jpg');
        background-size: cover;
        padding:0px;
        
        &:before {
            background-color: inherit;
        }
    }
    color: color('blue','base');
    height: calc(100vh - 90px); // 90px son la altura del menú en movil
    position: relative;

    .#{$ofertare-prefix}-interna-text{
        background-color:  rgba(20, 28, 39, 0.79);
        height: 100%;
        padding: 25% 5%;
        h1{
            font : {
                size: 10vw;
                weight: 300;
            }
            b{
                display: block;
            }
        }
        p.title{
            font-size: 5.5vw;
            font-weight: 300;
        }
        p.text_info_intena{
            margin-top: 20px;
            font-size: 4.5vw;
            font-weight: 400;
        }
    }
    h1, p { line-height: 1.1 }
    h1 { 
        font : {
            size: 12vw;
            weight: 300;
        }
        b { font-weight: 900 }
    }
    p { 
        font : {
            size: 6vw;
            weight: 300;
        }
        -webkit-font-smoothing: antialiased; /* Chrome, Safari */
        -moz-osx-font-smoothing: grayscale; /* Firefox */
    }

    &:before {
        background-color: rgba(white, 0.7);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    & > * {
        position: relative;
        z-index: 2;
    }


    @include media-breakpoint-up(sm) { 
        h1 { font-size: 10vw }
        p { font-size: 1.187rem }
        .#{$ofertare-prefix}-interna-text{
            h1 { font-size: 10vw }
            p { font-size: 1.187rem }
        }
    }
    @include media-breakpoint-up(md) { 
        h1 { font-size: 8vw }
        .#{$ofertare-prefix}-interna-text{
             padding: 25% 5%;
            h1 { 
                font-size: 8vw;
                b{
                    display: inline;
                } 
            }
        }
    }
    @include media-breakpoint-up(lg) { 
        background : {
            attachment : scroll;
            size: auto 100%;
        }
        height: 500px;

        &:before { display: none }

        h1 { font-size: 2.25rem }
        .btn { margin-bottom: 1em }
        .#{$ofertare-prefix}-interna-text{
            align-self:flex-start;
            width:55%;
            margin-left:0;
            height: auto;
            padding: 20px 50px;
            text-align:left;
            h1 { 
                font-size: 2.2rem;
                b{
                    display: inline;
                }
            }
            p{
                &.title{
                    font-size: 1.5rem;
                }
                &.text_info_intena{
                    font-size: 1.2rem;
                }
            }
        }
    }
  
}